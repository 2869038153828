import React from "react";
import { Link } from "react-router-dom";
import Expertise from '../Assets/BackdropImages/Expertise.jpg'
const OurExpertise = () => {
  return (
    <div className="our-expertise py-5 my-5">
      <div className="container">
        <div className="row">
          <div className="expertise-container px-4 col-lg-6 d-flex flex-column justify-content-center">
            <p className="section-title pb-0 mb-0">Our Expertise</p>
            <div className="red-underline"></div>
            <p className="section-content">
              With years of experience in the electrical industry, KK Electric
              and Tool House has become a trusted name for a diverse range of
              electrical products. From MCB Distribution Boards to Wiring
              Accessories, we offer a selection that ensures the highest safety
              and performance standards.
            </p>
            <button className="btn btn-danger btn-sm w-25"><Link className="nav-link" to="/about">Know More</Link></button>
          </div>
          <div className="expertise-img col-lg-6 d-flex justify-content-center align-items-center">
            <img src={Expertise} alt="" srcset="" width="105%" className="shadow"/>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default OurExpertise;
