import React from "react";
import AboutWelcome from "../Components/AboutWelcome";
import AboutWhy from "../Components/AboutWhy";
import "../Stylings/about.css";
import CallToAction from "../Components/CallToAction";
import AboutCorousal from "../Components/AboutCorousal";
import PageHeader from '../Components/PageHeader'
import aboutBackdrop from '../Assets/BackdropImages/aboutBackdrop.jpg'
const About = () => {
  return (
    <div>
      <PageHeader title="About Us" path='/' backdrop={aboutBackdrop}/>
      <AboutWelcome />
      <AboutWhy/>
      <CallToAction/>
      <AboutCorousal/>
    </div>
  );
};

export default About;
