import React from 'react'
import { Link } from 'react-router-dom'
const PageHeader = ({ title, path, backdrop }) => {
    return (
        <section className=' page-header' style={{backgroundImage: `url(${backdrop})`}}>
            <div className="page-header-overlay py-4">
                <div className="container">
                    <p className="page-title">
                        {title}
                    </p>
                    <p className="page-subtitle">
                        <Link className='text-white' to={path}>Home</Link> / <Link className='text-white'> {title}</Link>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default PageHeader