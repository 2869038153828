import React from 'react'
import { Link } from 'react-router-dom'

const CallToAction = () => {
    return (
        <section className='my-5 bg-dark text-white call-to-action'>
            <div className=" call-to-action-overlay py-3">
                <div className=" container main">
                    <div className="row">
                        <div className="col-md-12 heading">
                            <h5 className='text-center fs-4'>Our Store</h5>
                            <h2 className='section-title text-center p-0 m-3'>Explore Precision in Electrical Solutions</h2>
                            <p className='section-content p-0 m-0 text-center'>
                            Experience excellence in every connection with our premium Electrical Tools & Accessories, exclusively 
                                <br /> at K.K Electrical and Tool House.
                            </p>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12 ">
                           <Link className='nav-link w-100' to="/contact"> <button className='my-3 button btn btn-primary mx-5 w-50 '>Contact</button></Link>
                        </div>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction