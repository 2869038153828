import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Home from '../Views/Home'
import About from '../Views/About'
import Products from '../Views/Products'
import Contact from '../Views/Contact'
import ProductDetails from '../Components/ProductDetails'
import navLogo from '../Assets/Brand/main-logo.png'
const Navigation = () => {
  const products = [
    {
      productId: 0,
      productName: "MCB Distribution Board",
      productCoverImage: "../Assets/ProductImages/MCB-Distribution-Board-1.png",
      productModels: [
        {
          productModelId: 0,
          productModelTitle: "MCB Box",
          productSubModelImage: "../Assets/ProductImages/MCB-Distribution-Board-1.png",
          productSubModels: ["FMB-2", "FMB-4"],
          prodcutSubModelNames: ["MCB Box 2 Way", "MCB Box 4 Way"]
        },
        {
          productModelId: 1,
          productModelTitle: "MCB Distribution Board",
          productSubModelImage: "../Assets/ProductImages/MCB-Distribution-Board-2.png",
          productSubModels: ["SSPN-4", "SSPN-6", "SSPN-8", "SSPN-12", "SSPN-16"],
          prodcutSubModelNames: ["MCB DISTRIBUTION BOARD SINGLE DOOR SPN 4WAY", "MCB DISTRIBUTION BOARD SINGLE DOOR SPN 6WAY", "MCB DISTRIBUTION BOARD SINGLE DOOR SPN 8WAY", "MCB DISTRIBUTION BOARD SINGLE DOOR SPN 12WAY", "MCB DISTRIBUTION BOARD SINGLE DOOR SPN 16WAY"]
        },
        {
          productModelId: 2,
          productModelTitle: "MCB Distribution Board Double Door",
          productSubModelImage: "../Assets/ProductImages/MCB-Distribution-Board-3.png",
          productSubModels: ["DSPN-4", "DSPN-6", "DSPN-8", "DSPN-12", "DSPN-16"],
          prodcutSubModelNames: ["MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 4WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 6WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 8WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 12WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 16WAY"]
        },
        {
          productModelId: 3,
          productModelTitle: "MCB Distribution Board Double Door With Neutral Wire",
          productSubModelImage: "../Assets/ProductImages/MCB-Distribution-Board-4.png",
          productSubModels: ["FSPN-4", "FSPN-6", "FSPN-8", "FSPN-12", "FSPN-16"],
          prodcutSubModelNames: ["MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 4WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 6WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 8WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 12WAY", "MCB DISTRIBUTION BOARD DOUBLE DOOR SPN 16WAY"]
        },
      ]
    },
    {
      productId: 1,
      productName: "Accessories For MCB Distribution Board",
      productCoverImage: "../Assets/ProductImages/Accessories-MCB-1.png",
      productModels: [
        {
          productModelId: 0,
          productModelTitle: "MCB SHORTING LINK WITH SLEEVE",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-1.png",
          productSubModels: ["MSL-S", "MSL"],
          prodcutSubModelNames: ["MCB SHORTING LINK WITH SLEEVE (1 METER)", "MCB SHORTING LINK WITHOUT SLEEVE (1 METER)"]
        },
        {
          productModelId: 1,
          productModelTitle: "Earth Bar",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-2.png",
          productSubModels: ["EB-2", "EB-4", "EB-6", "EB-10", "EB-14"],
          prodcutSubModelNames: ["EARTH BAR SUITABLE FOR SPN 4WAY", "EARTH BAR SUITABLE FOR SPN 6WAY", "EARTH BAR SUITABLE FOR SPN 8WAY", "EARTH BAR SUITABLE FOR SPN 12WAY", "EARTH BAR SUITABLE FOR SPN 16WAY"]
        },
        {
          productModelId: 2,
          productModelTitle:"Neutral Bar",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-2.png",
          productSubModels: ["NB-2", "NB-4", "NB-6", "NB-10", "NB-14"],
          prodcutSubModelNames: ["NEUTRAL BAR SUITABLE FOR SPN 4WAY", "NEUTRAL BAR SUITABLE FOR SPN 6WAY", "NEUTRAL BAR SUITABLE FOR SPN 8WAY", "NEUTRAL BAR SUITABLE FOR SPN 12WAY", "NEUTRAL BAR SUITABLE FOR SPN 16WAY"]
        },
        {
          productModelId: 3,
          productModelTitle:"L-PAD",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-4.png",
          productSubModels: ["L-PAD", "L-PAD SMALL", "N-PAD"],
          prodcutSubModelNames: ["L-PAD FOR NEUTRAL BAR", "SMALL L-PAD FOR NEUTRAL BAR", "NORMAL PAD FOR NEUTRAL BAR"]
        },
      ]
    },
    {
      productId: 2,
      productName: "Cubicle Onload Switches",
      productCoverImage: "../Assets/ProductImages/onload-1.png",
      productModels: [
        {
          productModelId: 0,
          productModelTitle: "MCB SHORTING LINK WITH SLEEVE",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-1.png",
          productSubModels: ["MSL-S", "MSL"],
          prodcutSubModelNames: ["MCB SHORTING LINK WITH SLEEVE (1 METER)", "MCB SHORTING LINK WITHOUT SLEEVE (1 METER)"]
        },
        {
          productModelId: 1,
          productModelTitle: "Earth Bar",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-2.png",
          productSubModels: ["EB-2", "EB-4", "EB-6", "EB-10", "EB-14"],
          prodcutSubModelNames: ["EARTH BAR SUITABLE FOR SPN 4WAY", "EARTH BAR SUITABLE FOR SPN 6WAY", "EARTH BAR SUITABLE FOR SPN 8WAY", "EARTH BAR SUITABLE FOR SPN 12WAY", "EARTH BAR SUITABLE FOR SPN 16WAY"]
        },
        {
          productModelId: 2,
          productModelTitle:"Neutral Bar",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-2.png",
          productSubModels: ["NB-2", "NB-4", "NB-6", "NB-10", "NB-14"],
          prodcutSubModelNames: ["NEUTRAL BAR SUITABLE FOR SPN 4WAY", "NEUTRAL BAR SUITABLE FOR SPN 6WAY", "NEUTRAL BAR SUITABLE FOR SPN 8WAY", "NEUTRAL BAR SUITABLE FOR SPN 12WAY", "NEUTRAL BAR SUITABLE FOR SPN 16WAY"]
        },
        {
          productModelId: 3,
          productModelTitle:"L-PAD",
          productSubModelImage: "../Assets/ProductImages/Accessories-MCB-4.png",
          productSubModels: ["L-PAD", "L-PAD SMALL", "N-PAD"],
          prodcutSubModelNames: ["L-PAD FOR NEUTRAL BAR", "SMALL L-PAD FOR NEUTRAL BAR", "NORMAL PAD FOR NEUTRAL BAR"]
        },
      ]
    },



  ]
  return (
    <div>
      <nav class="navbar navbar-expand-lg bg-light">
        <div class="container">
          <Link class="navbar-brand" to="/"><img src={navLogo} alt="" srcset="" className='nav-logo' /></Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link class="nav-link" aria-current="page" to="/">Home</Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" aria-current="page" to="/about">About</Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" aria-current="page" to="/products">Products</Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" aria-current="page" to="/contact">Contact Us</Link>
              </li>
            </ul>
            <form class="d-flex" role="search">
              <button class="btn btn-outline-danger" type="submit"><Link to="/contact" className='nav-link'>Get Quote</Link></button>
            </form>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path='/' element={<Home products={products} />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path="/products" element={<Products products={products} />} />
        <Route path="/product/:productName" element={<ProductDetails products={products} />} />
      </Routes>
    </div>
  )
}

export default Navigation