import React from 'react'
import '../Stylings/contact.css'
const ContactUsMain = () => {
    return (
        <div id="contact" class="contact-area section-padding bg-light">
            <div class="container py-5">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="contact">
                            <form class="form" name="enq" method="post" action="contact.php" onsubmit="return validation();">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <input type="text" name="name" class="form-control mb-4" placeholder="Name" required="required" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="email" name="email" class="form-control mb-4" placeholder="Email" required="required" />
                                    </div>
                                    <div class="form-group col-md-12">
                                        <input type="text" name="subject" class="form-control mb-4" placeholder="Subject" required="required" />
                                    </div>
                                    <div class="form-group col-md-12">
                                        <textarea rows="6" name="message" class="form-control mb-4" placeholder="Your Message" required="required"></textarea>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <button type="submit" value="Send message" name="submit" id="submitButton" class="btn btn-contact-bg" title="Submit Your Message!">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="single_address">
                            <i class="fa fa-map-marker"></i>
                            <h4>Our Address</h4>
                            <p>Opposite of Hotel Krishna Continental, Bibi Wala Road, Bathinda</p>
                        </div>
                        <div class="single_address">
                            <i class="fa fa-envelope"></i>
                            <h4>Send your message</h4>
                            <p>contact@kkelectricindia.com</p>
                        </div>
                        <div class="single_address">
                            <i class="fa fa-phone"></i>
                            <h4>Call us on</h4>
                            <p>+91 98149 20800</p>
                        </div>
                        <div class="single_address">
                            <i class="fa fa-clock-o"></i>
                            <h4>Work Time</h4>
                            <p>Mon - Fri: 08.00 - 16.00. <br />Sat: 10.00 - 14.00</p>
                        </div>
                    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13791.328130960286!2d74.9470648!3d30.2133411!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391733f21068a073%3A0xf88733e2a9fef55d!2sK%20K%20electric%20and%20tool%20house!5e0!3m2!1sen!2sin!4v1703487013489!5m2!1sen!2sin" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default ContactUsMain