import React from "react";

export default function AboutWhy() {
  return (
    <div className="container-fluid whybg centered py-5 mb-5">
      <div className="section-title centered">
        <p className="sectio-title mb-0">Why Choose Us</p>
        <div className="red-underline m-auto mt-2" style={{width:"10%"}}></div>
      </div>

      <div className="container  mt-5">
        <div className="row px-3">
          <div className="col-md-6 col-lg-4 mb-4 p-3 hey">
            <div className="card text-center glow-on-hover container about-card border-0">
              <div className="card-body glowing-card">
                <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                <h5 className=" card-title text-muted">
                  Quality Assurance
                </h5>
                <div className="red-underline m-auto mt-2" style={{width:"10%"}}></div>
                <p className="section-content card-text text-dark pt-5">
                  We believe in delivering products that stand the test of time.
                  Our selection undergoes rigorous quality checks to ensure
                  durability and performance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 p-3 ">
            <div className="card text-center glow-on-hover container about-card border-0">
              <div className="card-body glowing-card">
              <i class="fa-solid fa-signs-post"></i>
                <h5 className=" card-title text-muted">
                Expert Guidance
                </h5>
                <div className="red-underline m-auto mt-2" style={{width:"10%"}}></div>
                <p className="section-content card-text text-dark pt-5">
                Need assistance or product recommendations? Our knowledgeable team is here to provide expert guidance, ensuring you make informed decisions for your projects with confidence and success.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-6 col-lg-4 mb-4 p-3">
            <div className="card text-center glow-on-hover container about-card border-0">
              <div className="card-body glowing-card">
              <i class="fa-solid fa-people-group"></i>
                <h5 className=" card-title text-muted">
                  Diverse Range
                </h5>
                <div className="red-underline m-auto mt-2" style={{width:"10%"}}></div>
                <p className="section-content card-text text-dark pt-5">
                 

                Whether you're working on a small home project or a large-scale industrial task, we've got you covered. Explore our diverse range of products, including high-quality MCB Distribution Boards, essential accessories like durable cable ties, reliable earth bars, and more for comprehensive solutions tailored to your specific needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React from 'react'

// function AboutWhy() {
//   return (
// <section className=' cards-section pt-5 pb-5'>
// <div className="container">
//   <div className="row">
// <div className="col-md-12"><h2 className='text-center'>Why Choose Us</h2></div>
//   </div>
//   <div className="row card-main">
//     <div className="col-md-6 col-lg-4 hello"></div>
//     <div className="col-md-6 col-lg-4 hello"></div>
//     <div className="col-md-6 col-lg-4 hello"></div>
//   </div>
// </div>
// </section>
//   )
// }

// export default AboutWhy
