// ProductDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from './PageHeader';
import productBackdrop from '../Assets/BackdropImages/productsBackdrop.jpg'
import '../Stylings/product.css'
const ProductDetails = ({ products }) => {
  const { productName } = useParams();
  const selectedProduct = products.find((product) => product.productName === productName);

  if (!selectedProduct) {
    return <div>No product found for {productName}</div>;
  }

  return (
    <>
      <PageHeader title="Products" path='/' backdrop={productBackdrop} />
      <div className="container mt-5">
        <p className='section-title mb-0'>{selectedProduct.productName} Details</p>
        <div className="red-underline mb-5"></div>
        <div className="row">
          {selectedProduct.productModels.map((productModel) => (
            <div key={productModel.productModelId} className="col-lg-4 col-md-6 mb-3 col-xs-12 col-sm-6">
              <div className="card product-card shadow">
                <div className="card-img-container">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: `url(${productModel.productSubModelImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition:'center center'
                    }}
                  ></div>
                </div> <div className="card-body">
                  <h5 className="card-title">{productModel.productModelTitle}</h5>
                  <button
                    className="btn btn-danger btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={`#productModal${productModel.productModelId}`}
                  >
                    View Models
                  </button>
                </div>
              </div>

              {/* Product Models Modal */}
              <div
                className="modal fade"
                id={`productModal${productModel.productModelId}`}
                tabIndex="-1"
                aria-labelledby={`productModalLabel${productModel.productModelId}`}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id={`productModalLabel${productModel.productModelId}`}>
                        {productModel.productName} Models
                      </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>CAT. NO./Size</th>
                            <th>DESCRIPTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productModel.productSubModels.map((subModel, index) => (
                            <tr key={index}>
                              <td>{subModel}</td>
                              <td>{productModel.prodcutSubModelNames[index]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
