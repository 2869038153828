import React from "react";

function ProductSection() {
  return (
    <section className="py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 py-4 products">
            <p className="section-title mb-0">Our Products</p>
            <div className="red-underline" style={{ width: "10%" }}></div>
            <p className="section-content">
              Polyworld endeavours to follow modern trend, hence offering you
              stylish and luxurious series of bath items. We have one of the
              best state of the art Indigenously designed products. The series
              includes plastic bathroom accessories , plastic taps, water
              storage tanks, unbreakable bathwares, plastic taps, blow moulded 4
              layer water storage tanks, garden pipe and other bathroom items.
              Further we also offer customized products as per your requirement
              and spaces. Give a glance to some range of products.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductSection;
