import React from 'react'

export default function AboutWelcome() {
  return (
    <section className="welcome-section py-5" align="center">
    <div className="container">            
        <div className="row clearfix">
     
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-title">
                    <p className='pb-0 mb-0'>About Us</p>
                    <div className="red-underline" style={{width:"10%", margin:"auto"}}></div>
                </div>                  
                <div className="section-content"><p>Welcome to KK Electrical and Tool House, your premier destination for high-quality electrical products and tools. At KK Electrical, we pride ourselves on being a trusted partner for professionals and DIY enthusiasts alike. Our extensive selection includes a diverse range of products, from MCB Distribution Boards to essential accessories like cable ties and earth bars. We stand out by ensuring the utmost quality in every item we offer, subjecting each product to rigorous quality checks to guarantee durability and optimal performance. Our experienced team of experts is committed to providing personalized guidance and recommendations, ensuring that you have the right tools for your specific projects. Customer satisfaction is at the core of our mission, and we are dedicated to delivering a seamless shopping experience, from product selection to timely delivery. Connect with us on social media to stay informed about our latest products, promotions, and industry insights. Thank you for choosing KK Electrical and Tool House as your trusted partner for premium electrical and tool solutions.</p></div>                   
            </div>
           </div>
    </div>
</section>
  )
}
