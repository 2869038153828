import React from 'react'
import HeaderCarousel from '../Components/HeaderCarousel'
import CallToAction from '../Components/CallToAction'
import WelcomeSection from '../Components/WelcomeSection'
import HomeProducts from '../Components/HomeProducts'
import OurExpertise from '../Components/OurExpertise'
import ToolsForJobs from '../Components/ToolsForJobs'

const Home = ({products}) => {
  return (
    <div>
      <HeaderCarousel/>
      <WelcomeSection/>
      <HomeProducts products={products}/>
      <OurExpertise/>
      <ToolsForJobs/>
      <CallToAction/>
    </div>
  )
}

export default Home