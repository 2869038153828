import React from 'react'
import ProductSection from '../Components/ProductSection'
import '../Stylings/product.css';
import ProductList from '../Components/ProductList';
import PageHeader from '../Components/PageHeader';
import productBackdrop from '../Assets/BackdropImages/productsBackdrop.jpg'
const Products = ({products}) => {
  return (
    <div>
      <PageHeader title="Products" path='/' backdrop={productBackdrop}/>
      <ProductSection />
      <ProductList products={products} />
    </div>
  )
}

export default Products