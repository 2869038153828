import React from "react";
import { Link } from "react-router-dom";
import qualityTools from '../Assets/BackdropImages/qualityTools.jpg'
const ToolsForJobs = () => {
  return (
    <div className="tools-for-jobs py-5">
      <div className="container">
        <div className="row">
            <div className="toolsforjob col-lg-6 d-flex justify-content-center align-items-center">
                <img src={qualityTools} alt="" srcset="" width="105%" className="shadow"/>
            </div>
          <div className="toolsforjob-container col-lg-6 d-flex flex-column justify-content-center">
            <p className="section-title mb-0">Quality Tools for Every Job</p>
            <div className="red-underline"></div>
            <p className="section-content">
              Empower your projects with our extensive collection of tools
              designed for both professionals and DIY enthusiasts. Whether
              you're on a construction site, tackling a home improvement
              project, or maintaining electrical systems, KK Electric and Tool
              House has the tools you need for the job.
            </p>
            <button className="btn btn-danger btn-sm w-25"><Link className="nav-link" to="/products">Know More</Link></button>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsForJobs;
