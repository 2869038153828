// ProductList.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../Stylings/product.css';

const ProductList = ({ products }) => {
  return (
    <div className="container my-5">
      <div className="row">
        {products.map((product) => (
          <div key={product.productName} className="col-lg-4 col-md-6 mb-3 col-xs-12 col-sm-6">
            <Link to={`/product/${product.productName}`} className="card product-card nav-link shadow">
              <div className="card-img-container">
                <div
                  className="card-img-top"
                  style={{
                    backgroundImage: `url(${product.productCoverImage})`,
                    backgroundSize: 'contain',
                    backgroundRepeat:'no-repeat',
                    backgroundPosition:'center center',
                  }}
                ></div>
              </div>
              <div className="card-body">
                <p className="card-title section-content">{product.productName}</p>
                <button className="btn btn-danger btn-sm">
                  <Link className="nav-link" to={`/product/${product.productName}`}>
                    View Product
                  </Link>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
