// HeaderCarousel.js
import React, { useEffect } from 'react';
import '../Stylings/home.css';
import $ from 'jquery';
import slide1Image from '../Assets/SliderImages/Slide1.jpg'
import slide2Image from '../Assets/SliderImages/Slide2.jpg'
import slide3Image from '../Assets/SliderImages/Slide3.jpg'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
window.jQuery = $;
window.$ = $;
const HeaderCarousel = () => {
  useEffect(() => {
    $('.owl-carousel').owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      autoplay: true,
      nav: true,
      navText: ["<i class='fas fa-chevron-left text-white'></i>", "<i class='fas fa-chevron-right text-white'></i>"],
      dots: true,
    });
  }, []);

  return (
    <div className="owl-carousel hero-carousel">
      <div className="hero-item" style={{backgroundImage: `url(${slide1Image})`}}>
        <div className="hero-content">
          <h2>Welcome to Electrical Tool House</h2>
          <p>Your one-stop shop for quality electrical tools and equipment</p>
        </div>
      </div>
      <div className="hero-item" style={{backgroundImage: `url(${slide2Image})`}}>
        <div className="hero-content">
          <h2>Explore Our Latest Products</h2>
          <p>Discover a wide range of tools designed for professionals and enthusiasts</p>
        </div>
      </div>
      {/* Add more items as needed */}
    </div>
  );
};

export default HeaderCarousel;
