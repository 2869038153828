import React from 'react';
import '../Stylings/product.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
const HomeProducts = ({ products }) => {
  const owlOptions = {
    items: 2,
    loop: true,
    margin: 10,
    autoplay: true,
    nav: true,
    navText: ["<i class='fas fa-chevron-left text-white'></i>", "<i class='fas fa-chevron-right text-white'></i>"],
    dots: true,
  };

  return (
    <section className='py-5 bg-light'>
      <div className="container">
        <div className="row ">
          <div className="mb-4 col-lg-12 d-flex flex-column justify-content-center align-items-center">
            <p className="section-title text-center mb-0">
              Our Products
            </p>
            <div className="red-underline" style={{ width: "10%" }}></div>
          </div>
        </div>
        <div className="row d-flex justify-content-around ">
          <div className="col-lg-6 ">
        <OwlCarousel className="owl-theme  w-100 mrr py-3 my-3 " {...owlOptions}> 
          {products.map((product) => (
            <div key={product.productId} className="item  mrr hei-dot">
              <div className="card product-card mb-2 heii-bg">
                <div className="card-img-container main-product-col  ">
                  <div
                    className="card-img-top "
                    style={{
                      backgroundImage: `url(${product.productCoverImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition:'center center',
                    }}
                  ></div>
                </div>
                
                <div className="card-body p-0 ms-2   mt-3 heii-2-content">
                  <h5 className="card-title font">{product.productName}</h5>
                  <button className='btn btn-danger btn-sm  '><Link className='nav-link' to={`/product/${product.productName}`}>View Product</Link></button>
                </div>
              </div>
            </div>
            
          ))}
          
        </OwlCarousel>
        </div>
        <div className="product-col  col-lg-5 border rounded d-flex justify-content-center align-items-center ">
         <div className="container bg-white shadow product-overlay w-75 h-75 d-flex align-items-center justify-content-center rounded">
            <div className='container main-product'>
                <h5 className='py-3'>Customer-Centric Excellence at<br/> <span className='product-span'>KK Electric</span> and Tool House</h5>
                <p className='my-3'>At KK Electrical and Tool House, we prioritize customer satisfaction, offering expertise and reliable solutions for your needs.</p>
                <div className="container-fluid">
                  <div className="row my-2 ">
                    <div className="col-md-6 ">
                    <ul className='list-inline'>
                <li><i class="fa-solid fa-check "></i>Reliable</li>
                  <li><i class="fa-solid fa-check"></i>Trustworthy</li>
                  <li><i class="fa-solid fa-check"></i>Transparent</li>
                  <li><i class="fa-solid fa-check"></i>Knowledgeable</li>
                </ul>
                    </div>
                    <div className="col-md-6">
                    <ul className='list-inline'>
                  <li><i class="fa-solid fa-check"></i>Prompt</li>
                  <li><i class="fa-solid fa-check"></i>Expert</li>
                  <li><i class="fa-solid fa-check"></i>Dependable</li>
                  <li><i class="fa-solid fa-check"></i>Integrity</li>
                </ul>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
          <div className="col-12 d-flex justify-content-start">
            <button className='btn btn-danger btn-sm'><Link to="/products" className="nav-link">View Product</Link></button>
          </div>
        </div>
            </div>
         </div>
        </div>
        </div>
        <div className="row pt-5">
          <div className="col-12 d-flex justify-content-center">
            <button className='btn btn-outline-danger btn-sm'><Link to="/products" className="nav-link">View All Prodcuts</Link></button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeProducts;
