/* global jQuery */
import React, { useEffect } from 'react';

function AboutCorousal() {
  useEffect(() => {
   
  jQuery(function ($) {
    "use strict";
    var $window = $(window);
    var windowsize = $(window).width();
    var $root = $("html, body");
    var $this = $(this);
 
 
    /*Testimonials 3columns*/
    $("#testimonial-slider").owlCarousel({
       items: 3,
       autoplay: 500,
       autoplayHoverPause: true,
       loop: true,
       margin: 30,
       dots: true,
       nav: false,
       responsive: {
          1280: {
             items: 3,
          },
          600: {
             items: 2,
          },
          320: {
             items: 1,
          },
       }
    });
 
 
 });


    jQuery(document).ready(function ($) {
      // Your jQuery code
    });
  }, []); // Empty dependency array to run the effect only once

  return (
    <div>
     <section id="our-testimonial" className="padding pb-5">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 text-center">
          <div className="heading-title bottom30 wow fadeInUp" data-wow-delay="300ms"> <span>Testimonials</span>
            <h2 className="">What People Say</h2>
          </div>
        </div>
      </div>
      <div className="red-underline m-auto mt-2" style={{width:"10%"}}></div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div id="testimonial-slider" className="owl-carousel">
            <div className="item">
              <div className="testimonial-wrapp"> <span className="quoted"><i className="fa fa-quote-right"></i></span>
                <div className="testimonial-text">
                  <p className="bottom40">Donec semper euismod nisi quis feugiat. Nullam finibus metus eget orci volutpat porta. Morbi quis arcu vulputate, dignissim mi ac, varius magna.</p>
                </div>
                <div className="testimonial-photo"><img alt="" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp"/></div>
                <h4 className="darkcolor">Sam David</h4>
                <small className="defaultcolor">Businessman</small> </div>
            </div>
            <div className="item">
              <div className="testimonial-wrapp"> <span className="quoted"><i className="fa fa-quote-right"></i></span>
                <div className="testimonial-text">
                  <p className="bottom40">Donec semper euismod nisi quis feugiat. Nullam finibus metus eget orci volutpat porta. Morbi quis arcu vulputate, dignissim mi ac, varius magna.</p>
                </div>
                <div className="testimonial-photo"><img alt="" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp"/></div>
                <h4 className="darkcolor">Jams Shah</h4>
                <small className="defaultcolor">Businessman</small> </div>
            </div>
          
            <div className="item">
              <div className="testimonial-wrapp"> <span className="quoted"><i className="fa fa-quote-right"></i></span>
                <div className="testimonial-text">
                  <p className="bottom40">Donec semper euismod nisi quis feugiat. Nullam finibus metus eget orci volutpat porta. Morbi quis arcu vulputate, dignissim mi ac, varius magna.</p>
                </div>
                <div className="testimonial-photo"><img alt="" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp"/></div>
                <h4 className="darkcolor">David Zucker</h4>
                <small className="defaultcolor">Businessman</small> </div>
            </div>
            <div className="item">
              <div className="testimonial-wrapp"> <span className="quoted"><i className="fa fa-quote-right"></i></span>
                <div className="testimonial-text">
                  <p className="bottom40">Donec semper euismod nisi quis feugiat. Nullam finibus metus eget orci volutpat porta. Morbi quis arcu vulputate, dignissim mi ac, varius magna.</p>
                </div>
                <div className="testimonial-photo"><img alt="" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp"/></div>
                <h4 className="darkcolor">Akten Jansen</h4>
                <small className="defaultcolor">Businessman</small> </div>
            </div>

           
           
            <div className="item">
              <div className="testimonial-wrapp"> <span className="quoted"><i className="fa fa-quote-right"></i></span>
                <div className="testimonial-text">
                  <p className="bottom40">Donec semper euismod nisi quis feugiat. Nullam finibus metus eget orci volutpat porta. Morbi quis arcu vulputate, dignissim mi ac, varius magna.</p>
                </div>
                <div className="testimonial-photo"><img alt="" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp"/></div>
                <h4 className="darkcolor">Albugdadi Raleway</h4>
                <small className="defaultcolor">Businessman</small> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  );
}

export default AboutCorousal;