import React from 'react'
import { Link } from 'react-router-dom'
import navLogo from '../Assets/Brand/main-logo.png'
const Footer = () => {
  return (
    <div>

      <footer class="text-center text-lg-start bg-body-tertiary text-muted">




        <section class="pt-5">
          <div class="container text-center text-md-start mt-5">

            <div class="row mt-3">

              <div class="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">

                <h6 class="text-uppercase fw-bold mb-4">
                  <img src={navLogo} alt="" srcset="" className='nav-logo' />
                </h6>
                <p className='section-content' style={{fontSize:"90%"}}>
                  At KK Electrical and Tool House, customer satisfaction is at the core of our business. Our knowledgeable team is dedicated to providing personalized service, expert advice, and prompt assistance. We strive to build lasting relationships with our customers, earning their trust through transparency, reliability, and integrity.
                </p>
              </div>



              <div class="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">

                <h6 class="text-uppercase fw-bold mb-4">
                  Quick Links
                </h6>
                <p>
                  <Link to="/" class="text-reset">Home</Link>
                </p>
                <p>
                  <Link to="/about" class="text-reset">About</Link>
                </p>
                <p>
                  <Link to="/products" class="text-reset">Products</Link>
                </p>
                <p>
                  <Link to="/contact" class="text-reset">Contact</Link>
                </p>
              </div>






              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                <p><i class="fas fa-home me-3"></i> Opposite of Hotel Krishna Continental, Bibi Wala Road, Bathinda</p>
                <p>
                  <i class="fas fa-envelope me-3"></i>
                  contact@kkelectricindia.com
                </p>
                <p><i class="fas fa-phone me-3"></i> +91 98149 20800</p>
              </div>

            </div>

          </div>
        </section>



        <div class="text-center p-4">
          © 2021 Copyright:
          <a class="text-reset fw-bold" href="https://mdbootstrap.com/">MDBootstrap.com</a>
        </div>

      </footer>

    </div>
  )
}

export default Footer