import React from 'react'
import homeImage from '../Assets/BackdropImages/newHomesec.png'

const WelcomeSection = () => {
    return (
        <section className='welcome-section py-5'>
            <div className="container welcome-overlay">
                <div className="row">
                    <div className="col-lg-6  ">
                        <p className="section-title mb-0 pb-4">
                           <span class="welcome-span"> Welcome to </span><br/><span class="home-span">K.K. Electric</span> & Tool House
                        </p>
                        {/* <div className="red-underline"></div> */}
                        <p className="section-content para ">
                        <i class=" icon fa-solid fa-greater-than"> </i>
                            Polyworld is globally recognised for high-quality, durable and luxurious bath-care accessories, and complete range of Bath Fittings.
                        </p>
                        <p className='section-content para'>
                        <i class=" icon fa-solid fa-greater-than"> </i>
                            Our products reflects art and expression giving you a magnificent experience. Backed by many years of experience we are gradually growing into a trust worthy brand for bathroom Fittings.
                        </p>
                        <p className='section-content para'>
                        <i class=" icon fa-solid fa-greater-than"> </i>
                            Our team of Qualified professionals and designers endeavour to provide best quality products with technological innovations which make our every product unique & iconic.
                        </p>
                        <p className='section-content para'>
                        <i class=" icon fa-solid fa-greater-than"> </i>
                            Our quality analysts ensures that every product is being manufactured by complying with the international standards and check products at every level of manufacturing so that we will be able to produce finest quality of products with efficiency.
                        </p>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center align-items-center welcome-img">
                        <img src={homeImage} alt="" srcset="" width="85%"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WelcomeSection