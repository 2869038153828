import React from 'react'
import PageHeader from '../Components/PageHeader'
import ContactUsMain from '../Components/ContactUsMain'
import contactBackdrop from '../Assets/BackdropImages/contactBackdrop.jpg'
const Contact = () => {
  return (
    <div>
      <PageHeader title="Contact Us" path="/" backdrop={contactBackdrop}/>
      <ContactUsMain/>
    </div>
  )
}

export default Contact